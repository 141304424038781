@import "../index.scss";

.modal {
  padding-left: 10px;
  padding-right: 10px;
}

.modal-header {
  border-bottom: 1px solid $color-accent;
  font-family: $font-family-secondary;
  margin-left: 24px;
  margin-right: 24px;
  padding-left: 0;
  padding-right: 8px;
  display: flex;
}

.modal-body {
  font-family: $font-family-secondary;
  padding-left: 24px;
  padding-right: 24px;
}

.modal-title {
  font-family: $font-family-secondary;
  font-size: 24px;
  line-height: 24px;
  color: $color-black;
}

.modal-text {
  font-family: $font-family-secondary;
  font-size: 16px;
  line-height: 22px;
  color: $color-subtle-primary;
}

.modal-icon {
  width: 24px;
  height: 24px;
  color: $color-text;
}

.modal-info {
  font-family: $font-family-secondary;
  font-size: 16px;
  line-height: 22px;
  color: $color-text;
}

.btn-close {
  background-image: url(../Assets/Icons/Union.svg);
  background-color: $color-light-tertiary;
  border-radius: 8px;
  opacity: 1;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(75, 77, 237, 0.25);
  opacity: 1;
}

/*PLAY*/
.play-modal .modal-dialog {
  max-width: 576px;
}

.play-icon {
  width: 18px;
  height: 18px;
  color: $color-subtle-primary;
}

.play-item {
  font-family: $font-family-secondary;
  font-size: 14px;
  line-height: 20px;
  color: $color-black;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid $color-accent;
}

.play-dropdown.show .dropdown-arrow::after,
.sort-dropdown.show .dropdown-arrow::after {
  transform: rotate(180deg);
}

.dropdown-image {
  display: flex;
  min-width: 250px;
  width: 93%;
  height: 120px;
  background-repeat: no-repeat;
  background-position: center;
}

/*FAQ*/
.faq-modal .modal-body {
  padding-top: 0;
}

.faq-modal .modal-header {
  border-bottom: 0;
  font-family: $font-family-secondary;
  margin-left: 24px;
  margin-right: 24px;
  padding-left: 0;
  padding-right: 8px;
  display: flex;
  justify-content: end;
  margin-bottom: 0;
  padding-top: 20px;
  padding-bottom: 10px;
}

.faq-modal .accordion-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  font-size: 14px;
}

.faq-modal .accordion-item {
  border-bottom: 1px solid $color-accent;
  border-left: 0;
  border-right: 0;
}

.faq-modal .accordion-flush .accordion-item:last-child {
  border-bottom: 1px solid $color-accent;
  border-left: 0;
  border-right: 0;
  border-top: 0;
}

.faq-modal .accordion-button {
  padding-left: 0;
  padding-right: 0;
}

.faq-modal .accordion-button:focus {
  border-color: $color-white;
  box-shadow: none;
}

.faq-modal .accordion-button:not(.collapsed) {
  font-family: $font-family-secondary;
  font-size: 16px;
  line-height: 22px;
  color: $color-subtle-primary;
  background-color: $color-white;
  box-shadow: none;
}

.faq-modal .accordion-button::after,
.dropdown-arrow .accordion-button::after {
  background-image: url(../Assets/Icons/chevron-down.svg);
}

/*UPLOAD*/

.upload-modal .modal-content {
  max-width: 370px;
}

.upload-modal .primary-button {
  width: 131px;
}

.upload-text {
  font-family: $font-family-primary;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $color-subtle-primary;
}

.upload-icon {
  color: $color-subtle-primary;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.upload-icon-folder {
  color: $color-light;
  width: 66.67px;
  height: 63.33px;
  z-index: 1000;
}

.upload-box {
  background-image: url(../Assets/Icons/Default.svg);
  background-repeat: no-repeat;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 1px;
  padding-right: 1px;
  background-position: center;
}

.caption-modal .modal-dialog {
  max-width: 358px;
}

.caption-image {
  height: 120px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.user-caption-image {
  height: 120px;
  width: 120px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.video-preview-modal .modal-dialog {
  min-width: 75%;
}

.video-preview-modal-body {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  min-height: 75vh;
}

.text-played-panorama {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #8C8CA1;
  margin-top: 5%;
}

.description-played-panorama{
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #8C8CA1;
}

.custom-progress-bar {
  height: 20px;
  margin-right: 2%;
  min-width: 82%;
  max-width: 82%;
}

.showProgress {
  margin: 10px;
  display: flex;

}
