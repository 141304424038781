@import "../../index.scss";

.header-nav {
  border-bottom: 0.875px solid $color-light-primary;
  border-top: 0.875px solid $color-light-primary;
  background-color: $color-white;
  padding-top: 20px;
  padding-bottom: 10px;
}

.header-menu-icon {
  width: 25px;
  height: 25px;
}

.header-dropdown {

  .btn-primary,
  .show>.btn-primary.dropdown-toggle:focus,
  .show>.btn-primary.dropdown-toggle {
    border: none;
    background: $color-white;
    color: $color-subtle-primary;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    box-shadow: none;
  }
}

.header-dropdown .dropdown-menu {
  position: absolute;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("../../Assets/Icons/menu-icon.svg");
}

.header-toggle {
  margin-right: 18px;
  border: none;
}

.header-toggle:focus {
  border: none;
  box-shadow: none;
}

.navbar-toggler-icon {
  width: 1em;
  height: 1em;
}

.nav-link {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-left: 12px;
  margin-right: 12px;
}

.navbar-light .navbar-nav .nav-link {
  color: $color-subtle-primary;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
  color: $color-dark-primary;
}

.nav-link-icon {
  margin-left: 15px;
  margin-right: 15px;
}

.nav-icons-box {
  display: contents;
  background-color: $color-white;
}

.nav-icons-responsive {
  display: flex;
  flex-direction: inherit;
  position: fixed;
  justify-content: center;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $color-white;
  border-bottom: 0.875px solid $color-light-primary;
  border-top: 0.875px solid $color-light-primary;
  padding: 10px 20px 10px 10px;
}

.left-icon {
  width: 24px;
  height: 24px;
  color: $color-dark-primary;
  margin-left: 30px;
}

/*PLAY*/
.play-dropdown .dropdown-menu {
  left: -195px;
  top: 55px;
}

.play-dropdown .dropdown-menu::after {
  content: url("../../Assets/Icons/top-break.svg");
  position: absolute;
  top: -14px;
  left: 84%;
  transform: translate(-50%, -50%);
  transform: rotate(180deg);
}

.play-music-text {
  width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: $color-dark-primary;
}

.nav-play-now-responsive {
  display: none;
}

// SIDEBAR

.sidebar {
  width: 300px;
}

.open-header-side {
  position: fixed;
  left: 300px;
  right: -300px;
  height: 70px;
  border-bottom: 0.875px solid $color-light-primary;
  border-top: 0.875px solid $color-light-primary;
  background-color: $color-white;
  padding-top: 20px;
  padding-bottom: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  width: 100%;
  transition-duration: 0.3s;
  transition-delay: 0s;
  transition-timing-function: ease-in-out;
}

.open-sidebar-form {
  position: fixed;
  bottom: 0;
  left: 300px;
  right: -300px;
  height: 70px;
  width: 99%;
  background-color: $color-white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  width: 99%;
  transition-duration: 0.3s;
  transition-delay: 0s;
  transition-timing-function: ease-in-out;
}

.close-sidebar-button {
  background-color: transparent;
  filter: invert(1) grayscale(100%) brightness(10%);
}

.company-logo {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.flag {
  width: 25px;
  height: 25px;
  border-radius: 10px;
}

.backdrop {
  background-color: transparent;
}

.sidebar-button {
  background-color: transparent;
  width: 100%;
  font-size: 19px;
  margin-bottom: 3px;
  margin-top: 3px;
  height: 40px;
  color: $color-subtle-primary;
  border-color: transparent;
}

.sidebar-button.active {
  background-color: $color-complement;
  color: $color-accent;
  border-radius: 15px;
}

.top-sidebar-content {
  border-top: 1px solid $color-accent;
  max-height: 30%;
  width: 90%;
}

.middle-sidebar-content {
  border-top: 1px solid $color-accent;
  width: 90%;
}

.bottom-sidebar-content {
  border-top: 1px solid $color-accent;
  width: 90%;
}

.notification-sidebar {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  width: 40px;
  height: 20px;
  color: $color-white;
  background-color: $color-notifications;
  border-radius: 10px;
}

/*SEARCH*/
.search-icon {
  margin-left: -30px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
  color: $color-subtle-primary;
  width: 24px;
  height: 24px;
}

.search-dropdown .search-box {
  width: 245px;
}

.search-box .textfield-primary {
  padding-right: 50px;
}

.search-dropdown .dropdown-menu {
  top: -90px;
  left: -10px;
  padding-left: 15px;
  padding-right: 0;
}

.search-dropdown .dropdown-menu::after {
  content: url("../../Assets/Icons/top-break.svg");
  position: absolute;
  top: 70px;
  left: 3%;
  transform: translate(-50%, -50%);
  transform: rotate(180deg);
}

/*USER*/
.text-username {
  font-family: $font-family-secondary;
  color: $color-dark-primary;
  font-size: 14px;
  line-height: 20px;
}

.text-user-tab {
  font-size: 18px !important;
  height: 52px;
  padding: 15px !important;
}

.user-icon {
  color: $color-brand-tertiary;
  margin-right: 10px;
}

.user-header {
  margin-top: 9px;
  margin-bottom: 17px;
}

.user-image {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.upload-modal .form-width {
  width: 100%;
}

.user-dropdown .dropdown-menu {
  width: 250px;
  top: -300px;
  left: -80px;
}

.user-dropdown .dropdown-menu::after {
  content: url("../../Assets/Icons/top-break.svg");
  position: absolute;
  top: 278px;
  left: 40%;
  transform: translate(-50%, -50%);
  transform: rotate(180deg);
}

/*NOTIFICATION*/
.notifications {
  top: 4px;
  right: 8px;
  height: 10px;
  width: 10px;
  background-color: $color-notifications;
  border-radius: 50%;
  display: inline-block;
}

.notification-title {
  font-family: "Geomanist";
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #639e86;
}

.notification-text {
  color: $color-dark-primary;
  margin-right: 25px;
  font-size: 18px;
  padding-top: 12px;
}

.small-text-brand {
  font-size: 12px !important;
}

.subheading-notifiction {
  padding: 24px !important;
  padding-bottom: 16px !important;
}

.subheading-notifiction:nth-last-child(-n+1) {
  padding-bottom: 24px !important;
}

.notification-seen {
  opacity: 0.4
}

.notification-dropdown .dropdown-menu,
.notification-dropdown .dropdown-item {
  padding: 0;
}

.notification-dropdown .dropdown-menu .dropdown-item {
  padding: 8px;
}

.notification-dropdown .dropdown-menu .dropdown-item:not(:last-child) {
  border-bottom: 1px solid $color-accent;
  padding-top: 8px;
  padding-bottom: 8px;
}

.notification-subheading-icon {
  width: 13px;
  color: $color-subtle-secondary;
}

.notification-box {
  margin-right: 0 !important;
}

.notification-dropdown .dropdown-menu {
  position: relative;
  left: -160px;
}

.notification-dropdown .dropdown-menu::after {
  content: url("../../Assets/Icons/top-break.svg");
  transform: translate(-50%, -50%);
  transform: rotate(180deg);
}

.wrapper {
  width: 250px;
  left: -127px;
  max-height: 300px;
  overflow-y: auto;
}

.notification-dropdown ::-webkit-scrollbar {
  width: 8px;
}

.notification-dropdown ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.notification-dropdown ::-webkit-scrollbar-thumb {
  background: $color-subtle-secondary;
  border-radius: 10px;
}

.nav-icons-box {
  display: flex;
  flex-basis: auto;
  align-items: center;
  background-color: $color-white;
}

@media (max-width: 992px) {
  .header-nav {
    border-bottom: 0.875px solid $color-light-primary;
    background-color: $color-white;
    padding-top: 30px;
    padding-bottom: 20px;
    left: 3%;
    right: 3%;
  }

  .small-form {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 80px;
    width: 100%;
    background-color: $color-white;
  }

  .search-box {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 15px;
    padding-top: 15px;
    width: 100%;
  }

  .search-box .textfield-primary {
    font-size: 19px;
    height: 60px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 50px;
  }
}

/*md*/
@media (min-width: 768px) {
  .header-logo {
    margin-left: 44px;
  }

  .nav-link-icon {
    margin-left: 50px;
    margin-right: 50px;
  }

  .nav-icons-responsive {
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }

  /*PLAY*/
  .nav-play-now-responsive .nav-link-icon {
    margin-right: 5px;
  }

  .play-dropdown .dropdown-menu {
    width: 306px;
    left: -235px;
    top: 60px;
  }

  .play-dropdown .dropdown-menu::after {
    content: url("../../Assets/Icons/top-break.svg");
    position: absolute;
    top: -3px;
    left: 90%;
    transform: translate(-50%, -50%);
  }

  .play-music-text {
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: $color-dark-primary;
  }

  .nav-play-now-responsive {
    display: flex;
    flex-direction: inherit;
  }

  .nav-icons-responsive {
    background-color: $color-white;
  }

  /*SEARCH*/
  .search-dropdown .search-box {
    width: 320px;
  }

  .sort-dropdown .dropdown-toggle {
    padding-right: 10px;
  }

  .search-dropdown .dropdown-menu {
    top: -90px;
    left: -100px;
    padding-right: 15px;
  }

  .search-dropdown .dropdown-menu::after {
    content: url("../../Assets/Icons/top-break.svg");
    position: absolute;
    top: 70px;
    left: 22%;
    transform: translate(-50%, -50%);
    transform: rotate(180deg);
  }

  /*USER*/
  .user-dropdown .dropdown-menu {
    top: -300px;
    left: -80px;
  }

  .user-dropdown .dropdown-menu::after {
    content: url("../../Assets/Icons/top-break.svg");
    position: absolute;
    top: 278px;
    left: 40%;
    transform: translate(-50%, -50%);
    transform: rotate(180deg);
  }

  .upload-modal .form-width {
    width: 100%;
  }

  .flag {
    height: 25px;
    width: 25px;
    border-radius: 10px;
    cursor: pointer;
  }
}

@media (min-width: 992px) {
  .header-nav {
    height: 95px;
  }

  .play-music-text {
    text-align: left;
    width: auto;
    min-width: 80px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: $color-dark-primary;
  }

  .header-dropdown .dropdown-menu {
    top: 60px;
  }

  .nav-link-icon {
    margin-left: 4px;
    margin-right: 4px;
  }

  .nav-icons-responsive {
    position: unset;
    border: none;
    padding: 0;
  }

  /*PLAY*/
  .play-underline-dropdown {
    border-bottom: 1.5px solid #8C8CA1;
  }

  .play-dropdown .dropdown-menu {
    width: 306px;
    left: 20px;
  }

  .play-dropdown .dropdown-menu::after {
    content: url("../../Assets/Icons/top-break.svg");
    position: absolute;
    bottom: 95%;
    left: 55%;
    transform: translate(-50%, -50%);
  }

  /*SEARCH*/
  .search-dropdown .dropdown-menu {
    left: -326px;
  }

  /*USER*/
  .user-dropdown .dropdown-menu {
    left: -160px;
  }

  .contact-modal .modal-dialog {
    max-width: 320px !important;
  }

  .upload-modal .form-width {
    width: 100%;
  }

  .upload-modal .modal-dialog {
    max-width: 370px !important;
  }

  .user-dropdown .dropdown-menu::after,
  .notification-dropdown .dropdown-menu::after,
  .search-dropdown .dropdown-menu::after {
    content: url("../../Assets/Icons/top-break.svg");
    position: absolute;
    bottom: 100%;
    left: 55%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 1200px) {
  .play-music-text {
    text-align: left;
    width: auto;
    min-width: 80px;
    max-width: 300px;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: $color-dark-primary;
  }
}