@import "../../index.scss";

.heart {
  width: 45px;
  height: 45px;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  top: 8px;
  right: 8px;
}

.heart-unliked {
  background-color: $color-white;
}

.heart-liked {
  background-color: $color-notifications;
}

.heart-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.heart-icon-liked {
  color: $color-white;
}

.heart-icon-unliked {
  color: $color-dark-secondary;
}
