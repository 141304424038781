@import "../../index.scss";

.title{
    font-weight: 500;
    font-size: 24px;
    font-family: 'Geomanist';
    color: #000000;
}

.text{
    font-weight: 400;
    font-size: 16px;
    font-family: 'Geomanist';
    color: #000000;
}

.info{
    font-weight: 400;
    font-size: 16px;
    font-family: 'Geomanist';
    color: #4A4A68;
}