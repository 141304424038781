@import "../../index.scss";

.dd-menu .dropdown-menu.show {
  height: 220px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.dd-item.dropdown-item.active {
  background-color: $color-brand-secondary;
}
