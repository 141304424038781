@import "../../index.scss";

.login-background {
  background: url("../../Assets/Images/Login/login-background.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100% 100%;
  flex: 1;
  height: 100%;
  min-height: 100vh;
}

.login-background-mobile {
  background: url("../../Assets/Images/Login/login-background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  min-height: 100vh;
}

.login-text {
  font-size: 18px;
  line-height: 21px;
}

.login-text-left {
  color: $color-dark-secondary;
  width: 300px;
}

.login-text-right {
  color: $color-white;
  width: 350px;
}

.login-text-welcome {
  font-size: 22px;
  line-height: 26px;
  color: $color-white;
}

.sign-up-position {
  bottom: 60px;
}

.logo-mobile {
  width: 280px;
}

.login-text-mobile {
  color: $color-white;
  font-family: $font-family-tertiary;
  font-size: 18px;
  line-height: 27px;
  width: 300px;
}

.sign-up-text-mobile {
  font-family: $font-family-tertiary;
  font-size: 18px;
  line-height: 27px;
  color: $color-white;
}

.sign-up-text-mobile-bold,
.sign-up-text-mobile-bold:hover {
  color: $color-white;
  font-weight: bold;
}

.login-input-icon {
  margin-left: -20px;
  cursor: pointer;
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translate(-50%, -50%);
  color: $color-white;
}

.eye-icon {
  margin-left: -30px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
  color: $color-white;
}

.textfield-login.textfield:focus:valid {
  border: 1px solid $color-success;
  box-shadow: 0px 0px 0px 4px rgba(49, 208, 170, 0.2);
  background: url("../../Assets/Icons/Success.svg");
  background-repeat: no-repeat;
  padding-right: 2.5em;
  background-position: right 16px center;
  background-size: 1.3em 1.3em;
}

.textfield-login.textfield:focus:invalid {
  border: 1px solid $color-warning;
  box-shadow: 0px 0px 0px 4px rgba(237, 75, 158, 0.2);
  background: url("../../Assets/Icons/Error.svg");
  background-repeat: no-repeat;
  padding-right: 2.5em;
  background-position: right 16px center;
  background-size: 1.3em 1.3em;
}

.textfield-login.textfield:disabled {
  background-color: $color-light !important;
  box-shadow: none;
  background: url("../../Assets/Icons/Disabled.svg");
  background-repeat: no-repeat;
  padding-right: 2.5em;
  background-position: right 16px center;
  background-size: 1.3em 1.3em;
}

.textfield-password-mobile:focus:valid,
.textfield-password-mobile:focus:invalid,
.textfield-password-mobile:focus:disabled {
  background: none !important;
}

.form-width {
  width: 290px;
}

@media (min-width: 576px) {
  .form-width {
    width: 320px;
  }
}
