@import "../../index.scss";

/*MEDIA CARD*/
.media-card {
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 0;
  width: 23%;
  max-height: 500px;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 20px;
}

.media-card-home-recommended {
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 0;
  width: 100%;
  max-height: 500px;
  margin-left: -0.75%;
  margin-right: 1%;
  margin-bottom: 20px;
  height: 95%;
}

.media-image {
  border-radius: 0;
  height: 206px;
  object-fit: cover;
  object-position: center;
}

.media-icon {
  height: 11px;
  color: $color-dark-tertiary;
}

.media-body {
  min-height: 70px;
}

.media-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $color-dark-secondary;
  width: 100%;
}

.media-text {
  font-size: 14px;
  line-height: 16px;
  color: $color-brand-secondary;
}

.pencil {
  width: 45px;
  height: 45px;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  top: 60px;
  right: 8px;
  background-color: $color-white;
}

.trash {
  width: 45px;
  height: 45px;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  top: 112px;
  right: 8px;
  background-color: $color-white;
}

.pencil-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.info-icon {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.trash-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

/*FOLDER-CARD*/

.folder-card-box-large {
  width: 48%;
  min-width: 150px;
  margin-left: 1%;
  margin-right: 1%;
  height: auto;
}

.folder-card-box-large-home {
  width: 97.5%;
  min-width: 150px;
  margin-right: 1%;
  height: 92%;
}

.folder-card-box-small {
  width: 23%;
  min-width: 149px;
  margin-left: 1%;
  margin-right: 1%;
  height: auto;
}

.folder-card-box-small-category {
  width: 23%;
  min-width: 149px;
  margin-left: 1%;
  margin-right: 1%;
  height: auto;
}

.folder-under-card {
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
  z-index: -1;
}

.folder-card {
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  margin-bottom: 20px;
}

.folder-under-card-large {
  top: 3.33%;
  left: 2.19%;
}

.folder-under-card-large-home {
  top: 3.33%;
  left: 2.19%;
}

.folder-under-card-small {
  top: 3.33%;
  left: 5.19%;
}

.folder-under-card-small-category {
  top: 3.33%;
  left: 5.19%;
}

.folder-card-large {
  width: 100%;
  height: 306px;
}

.folder-card-large-home {
  width: 100%;
  height: 100%;
}

.folder-card-small {
  width: 100%;
  height: 306px;
}

.folder-card-small-category {
  width: 100%;
  height: 318px;
  margin-top: -7.5px;
}

.folder-image {
  border-radius: 0;
  height: 238px;
  object-fit: cover;
  object-position: center;
}

.folder-icon {
  color: $color-brand-secondary;
  margin-right: 16px;
}

//SYNCING

.sync-card {
  justify-self: end;
  align-self: center;
}

.sync-dd .dropdown-menu.show {
  height: 180px;
  width: 190px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.sync-item.dropdown-item.active {
  background-color: $color-brand-secondary;
}

@media (max-width: 767px) {
  /*FOLDER-CARD*/

  .folder-under-card-small {
    top: 3.33%;
    left: 2.5%;
  }
  
  .folder-under-card-small-category {
    top: 3.33%;
    left: 2.5%;
  }

  .folder-card-box-large {
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    height: auto;
  }

  .folder-card-box-large-home {
    width: 100%;
    min-width: 150px;
    margin-right: 1%;
    height: auto;
  }

  .folder-card-large-home {
    width: 100%;
    height: auto;
  }

  .folder-card-box-small {
    width: 48%;
    margin-left: 1%;
    margin-right: 1%;
    height: auto;
  }

  .folder-card-box-small-category {
    width: 48%;
    margin-left: 1%;
    margin-right: 1%;
    height: auto;
  }

  .media-card {
    box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 0;
    width: 48%;
    height: auto;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  /*FOLDER-CARD*/

  .folder-card-box-large {
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    height: auto;
  }

  .folder-card-box-large-home {
    width: 100%;
    min-width: 150px;
    margin-right: 1%;
    height: auto;
  }

  .folder-card-large-home {
    width: 100%;
    height: auto;
  }

  .folder-card-box-small {
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    height: auto;
  }

  .folder-card-box-small-category {
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    height: auto;
  }

  .folder-card-large {
    width: 100%;
    height: 216px;
  }

  .folder-card-small {
    width: 100%;
    height: 216px;
  }

  .folder-card-small-category {
    width: 100%;
    height: 216px;
  }

  .media-card {
    box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 0;
    width: 48%;
    height: auto;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 10px;
  }

  .media-image {
    border-radius: 0;
    height: 156px;
  }

  .folder-image {
    border-radius: 0;
    height: 156px;
  }

  .long-title {
    width: 100%;
  }
}

/*CATEGORIES*/
.category-title {
  font-family: $font-family-tertiary;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: $color-dark-secondary;
  flex-grow: 1;
}

.long-title-small {
  max-width: 160px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.long-title-small-category {
  max-width: 160px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.long-title-long {
  max-width: 250px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.media-upload {
  background: $color-brand-secondary;
  height: 238px;
}

.text-truncate-container {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/*@media (min-width: 992px) {
  /*FOLDER-CARD*/
/*
  .folder-card-box-large {
    width: 48%;
    margin-left: 1%;
    margin-right: 1%;
    height: auto;
  }

  .folder-card-box-small {
    width: 23%;
    margin-left: 1%;
    margin-right: 1%;
    height: auto;
  }

  .media-card {
    box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 0;
    width: 23%;
    height: auto;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 20px;
  }
}*/


/*@media (max-width: 992px) {
  /*FOLDER-CARD*/
/*
  .folder-card-box-large {
    width: 48%;
    margin-left: 1%;
    margin-right: 1%;
    height: auto;
  }

  .folder-card-box-large-home {
    width: 100%;
    min-width: 150px;
    margin-left: 1%;
    margin-right: 1%;
    height: auto;
  }

  .folder-card-large-home {
    width: 100%;
    height: auto;
    min-height: auto;
  }

  .folder-card-box-small {
    width: 48%;
    margin-left: 1%;
    margin-right: 1%;
    height: auto;
  }

  .media-card {
    box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 0;
    width: 23%;
    height: auto;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 20px;
  }
}*/

.dropdown {
  position: relative;
  cursor: pointer;
  user-select: none;
  z-index: 4;
  margin-top: -5%;

  .dot {
    font-size: 30px;
    cursor: pointer;
  }

  .menu {
    position: absolute;
    top: 100%;
    right: 0;
    background: #fff;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    padding: 5px;
    display: none;
    z-index: 5;

    .menu-option {
      cursor: pointer;
      padding: 5px;
    }
  }

  &:hover {
    .menu {
      display: block;
    }
  }
}
