@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Work+Sans:wght@100;200;300;400&display=swap");

@font-face {
  font-family: "Geomanist";
  src: local("Geomanist"),
    url(./Assets/Fonts/geomanist-regular-webfont.woff) format("woff");
}

$font-family-primary: "Work Sans", sans-serif;
$font-family-secondary: "Geomanist";
$font-family-tertiary: "Poppins", sans-serif;

$color-white: #ffffff;
$color-black: #000000;
$color-text: #4a4a68;
$color-accent: #ecf1f4;
$color-subtle-primary: #27272C;
$color-subtle-secondary: #7f8387;
$color-dark-primary: #0e0e2c;
$color-dark-secondary: #0e0e0e;
$color-dark-tertiary: #0d0d0d;
$color-brand: #8dc63f;
$color-brand-primary: #80cc28;
$color-brand-secondary: #639e86;
$color-brand-tertiary: #31d0aa;
$color-light: #fafcfe;
$color-light-primary: #ececec;
$color-light-secondary: #c4c4c4;
$color-light-tertiary: #efeffd;
$color-notifications: #f53d7a;
$color-success: #46d5b2;
$color-warning: #ed4b9e;
$color-complement: #4b4ded;

body {
  box-sizing: border-box;
  font-family: $font-family-primary;
  font-size: 16px;
  line-height: 22px;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

.cont {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.open-side {
  position: fixed;
  left: 320px;
  right: -320px;
  top: 0;
  bottom: 0;
  width: 100%;
  transition-duration: 0.3s;
  transition-delay: 0s;
  transition-timing-function: ease-in-out;
}

.page-container {
  min-width: 70%;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-bottom: 95px;
}

.page-container-no-favorites {
  min-width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 236px auto;
  margin-bottom: 236px auto;
}

.no-favorites-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 26px;
}

@media (min-width: 576px) {
  .page-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 120px;
    margin-bottom: 95px;
  }
}

@media (min-width: 768px) {
  .page-container {
    width: 95%;
    display: flex;
    flex-direction: column;
    margin-top: 120px;
    margin-bottom: 75px;
  }
}

@media (min-width: 992px) {
  .page-container {
    width: 85%;
    display: flex;
    flex-direction: column;
    margin-top: 120px;
    margin-bottom: 35px;
  }
}

@media (min-width: 1200px) {
  .page-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-top: 120px;
    margin-bottom: 35px;
  }
}

@media (min-width: 1400px) {
  .page-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    margin-top: 120px;
    margin-bottom: 35px;
  }
}

@media (max-width: 576px) {
  .page-container {
    width: 92%;
    display: flex;
    flex-direction: column;
    margin-top: 120px;
    margin-bottom: 75px;
  }

  .page-container-no-favorites {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 236px auto;
    margin-bottom: 236px auto;
  }
}

/*MARGIN*/
.mt-subheader {
  margin-top: 20px;
}

.mb-subheader {
  margin-bottom: 15px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-15 {
  margin-left: 15px;
}

.mr-10 {
  margin-right: 10px;
}

/*TEXT*/

.subheading-modal {
  font-family: $font-family-tertiary;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.subheading-subtle {
  font-family: $font-family-tertiary;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: $color-subtle-primary;
}

.active {
  color: $color-dark-primary;
}

.small-text {
  font-size: 12px;
  line-height: 14px;
}

.small-text-subtle {
  color: $color-subtle-primary;
}

.small-text-subtle-secondary,
.small-text-subtle-secondary:hover {
  color: $color-subtle-secondary !important;
}

.small-text-subtle-secondary-bold,
.small-text-subtle-secondary-bold:hover {
  color: $color-subtle-secondary;
  font-weight: bold;
}

.small-text-brand {
  font-family: $font-family-secondary;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $color-brand-secondary;
}

/*ICON*/
.icon-subtle {
  width: 18px;
  height: 18px;
  color: $color-subtle-primary;
}

/*TEXTFIELD*/
.textfield {
  &-primary {
    background-color: $color-accent;
    box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1);
    border-radius: 8px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: $color-subtle-primary;
    height: 48px;

    &::placeholder {
      color: $color-subtle-primary;
    }

    &:active {
      border: 1px solid $color-complement;
      box-shadow: 0px 0px 0px 4px rgba(75, 77, 237, 0.2);
    }
  }

  &-secondary {
    font-family: $font-family-tertiary;
    text-transform: lowercase;
    background-color: transparent;
    border-radius: 100px;
    border: 1px solid $color-white;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 12px;
    line-height: 18px;
    color: $color-white;
    padding-left: 4em;
    padding-right: 4em !important;

    &::placeholder {
      color: $color-white;
      text-transform: uppercase;
    }

    &:focus {
      text-transform: none;
      background-color: transparent;
      color: $color-white;
      box-shadow: 0px 0px 0px 4px rgba(49, 208, 170, 0.2);
      border: 1px solid $color-success;
    }
  }
}

.textarea {
  &-primary {
    background-color: $color-accent;
    box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1);
    border-radius: 8px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: $color-subtle-primary;

    &::placeholder {
      color: $color-subtle-primary;
    }

    &:active {
      border: 1px solid $color-complement;
      box-shadow: 0px 0px 0px 4px rgba(75, 77, 237, 0.2);
    }
  }

  &-secondary {
    font-family: $font-family-tertiary;
    text-transform: lowercase;
    background-color: transparent;
    border-radius: 100px;
    border: 1px solid $color-white;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 12px;
    line-height: 18px;
    color: $color-white;
    padding-left: 4em;
    padding-right: 4em !important;

    &::placeholder {
      color: $color-white;
      text-transform: uppercase;
    }

    &:focus {
      text-transform: none;
      background-color: transparent;
      color: $color-white;
      box-shadow: 0px 0px 0px 4px rgba(49, 208, 170, 0.2);
      border: 1px solid $color-success;
    }
  }
}

.input-group-textfield:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.input-group-textfield
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

/*BUTTON*/
.primary-button,
.primary-button:focus,
.primary-button:hover {
  height: 48px;
  background-color: $color-brand;
  box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1);
  border-radius: 8px;
  border: none;
  line-height: 16px;
  text-transform: uppercase;
  color: $color-white;
  font-family: $font-family-tertiary;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 3%;

  &:active {
    box-shadow: 0px 0px 0px 4px rgba(49, 208, 170, 0.2) !important;
    border: 1px solid $color-success;
  }
}

.secondary-button,
.secondary-button:focus,
.secondary-button:hover {
  height: 45px;
  background-color: $color-brand-primary;
  border-radius: 100px;
  font-family: $font-family-tertiary;
  font-size: 18px;
  line-height: 27px;
  border: none;
  text-transform: uppercase;
  color: $color-white;

  &:focus {
    box-shadow: 0px 0px 0px 4px rgba(49, 208, 170, 0.2) !important;
  }
}

.tertiary-button,
.tertiary-button:focus,
.tertiary-button:hover {
  width: 117px;
  height: 48px;
  background-color: $color-white;
  border: 1px solid $color-light-tertiary;
  border-radius: 8px;
  font-family: $font-family-primary;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: $color-brand-secondary;
  text-transform: uppercase;

  &:active {
    box-shadow: 0px 0px 0px 4px rgba(49, 208, 170, 0.2) !important;
    border: 1px solid $color-success;
  }
  &:focus {
    box-shadow: 0px 0px 0px 4px rgba(49, 208, 170, 0.2) !important;
  }
}

.btn-primary:active {
  border: none !important;
  box-shadow: none !important;
}

/*DROPDOWN*/
.dropdown {
  &-menu {
    border: none;
    box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
    background: $color-white;
    border-radius: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &-icon {
    color: $color-brand-tertiary;
    margin-right: 10px;
  }

  &-item:active {
    color: $color-white;
    text-decoration: none;
    background-color: $color-accent;
  }
}

.primary-dropdown .dropdown-item {
  font-family: $font-family-secondary;
  font-size: 14px;
  line-height: 20px;
  color: $color-subtle-primary;
  white-space: inherit;
  padding-top: 4px;
  padding-bottom: 4px;
}

.primary-dropdown .btn-primary,
.show > .btn-primary.dropdown-toggle:focus,
.show > .btn-primary.dropdown-toggle {
  border: none;
  box-shadow: none;
  background-color: $color-white;
}

.show > .btn-primary.dropdown-toggle {
  color: $color-dark-primary;
}

.primary-dropdown .dropdown-toggle::after {
  display: none;
}

.secondary-dropdown {
  .dropdown-menu {
    box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 8px;
    padding: 16px;
  }

  .dropdown-item {
    font-family: $font-family-secondary;
    font-size: 14px;
    line-height: 20px;
    color: $color-dark-primary;
    border: 1px solid $color-accent;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
  }

  .dropdown-item:not(:last-child) {
    margin-bottom: 8px;
  }

  .dropdown-toggle::after {
    display: none;
  }
}

.dropdown-arrow .accordion-item {
  border: none;
}

.dropdown-arrow .accordion-button {
  padding: 0;
  border: none;
}

.dropdown-arrow .accordion-button:not(.collapsed) {
  color: $color-subtle-primary;
  background-color: $color-white;
}

.dropdown-arrow .accordion-button:focus {
  border: none;
  box-shadow: none;
}

.dropdown-arrow .accordion-button:not(.collapsed) {
  color: $color-white;
  box-shadow: none;
}

.dropdown-arrow::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url(./Assets/Icons/chevron-down.svg);
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

@media (min-width: 992px) {
  .dropdown-position .dropdown-menu::after {
    content: url("./Assets/Icons/top-break.svg");
    position: absolute;
    top: -10px;
    left: 75%;
    transform: translate(-50%, -50%);
  }
}

.sort-dropdown .dropdown-toggle {
  padding-right: 0px;
}

.sort-dropdown .dropdown-menu {
  inset: 0 auto auto -60px !important;
  min-width: 11rem;
}

/*BREADCRUMB*/
.breadcrumb-categories .breadcrumb-item + .breadcrumb-item::before {
  content: url(./Assets/Icons/chevron-right.svg);
  opacity: 50%;
}

.breadcrumb-categories .breadcrumb-item + .breadcrumb-item:nth-last-child(-n+1)::before {
  content: url(./Assets/Icons/chevron-right.svg);
  opacity: 100% !important;
}

.breadcrumb-categories.breadcrumb {
  margin-bottom: 0;
}

.breadcrumb-item.active {
  color: $color-dark-primary;
}
.breadcrumb-item a {
  color: $color-subtle-primary;
}
