@import "../../index.scss";

.play-underline-dropdown {
    border-bottom: 1.5px solid #8C8CA1;
}

.media-text-header {
    font-size: 14px;
    line-height: 16px;
    color: $color-brand-secondary;
}

.media-title-header {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: $color-dark-secondary;
    width: 100%;
}

.text-truncate-container-header {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.media-icon-header {
    height: 12px;
    width: 12px;
    color: #27272C;
}

.image-header {
    width: 274px;
    height: 178px;
    position: relative;
}

.media-image-header {
    width: 100%;
    height: auto;
    max-height: 100%;
    border-radius: 4px;
}

.image-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #27272CCC;
    opacity: 0.8;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 100px;
    width: 165px;
    height: 45px;
}

.image-button:hover {
    opacity: 1.0;
}

.dropdown-icon-image {
    color: white;
    margin-right: 10px;
}

.media-full-text {
    font-family: 'Work Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #D1D1D8;
}